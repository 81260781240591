import { Box, Typography } from "@mui/material";
import React, { forwardRef } from "react"; // Import forwardRef
import "./selectcomponent.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Use forwardRef to forward the ref
const SelectComponent = forwardRef(({ label, value, onClick,disabled }, ref) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minWidth: "210px",
        width: "100%",
        height: "70px",
        border: "1px solid #FEF5D8",
        borderRadius: "3px",
      }}
      onClick={() => {
        if (disabled) return; // Prevent click action if disabled
        onClick();
      }}
      ref={ref} // Attach the ref here
     
    >
      <Box>
        <Typography
          sx={{ fontSize: "12px", color: "#FFFFFF", ml: 2, fontWeight: "200" }}
        >
          {label}
        </Typography>
        <Typography sx={{ fontSize: "16px", color: "#FFFFFF", ml: 2 }}>
          {value}
        </Typography>
      </Box>
      <Box>
        <KeyboardArrowDownIcon sx={{ color: "#FFFFFF", mr: 2 }} />
      </Box>
    </Box>
  );
});

// Export the component
export default SelectComponent;
