import logo from './logo.svg';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import { Navigate, Outlet, createBrowserRouter } from'react-router-dom';
import WidgetDynamicLoading from './widgets/WidgetDynamicLoading';
import BookNowForm from './widgets/horecaWidget/BooknowForm';
import { Provider } from 'react-redux'
import { store } from './store/Store';
import PaymentSuccess from './widgets/horecaWidget/PaymentSuccess';
import Paymentfailure from './widgets/horecaWidget/Paymentfailure';
import PaymentPending from './widgets/horecaWidget/PaymentPending';
import { Suspense } from 'react';
import { useTranslation} from 'react-i18next';
import SuccessScreen from './widgets/feedbackWidget/SuccessScreen';
import CancelReservation from './widgets/horecaWidget/CancelReservation';


const hasValidQueryParams = (search) => {
  const params = new URLSearchParams(search);
  const widgetSetupId = params.get("widgetsetupid");
  const key = params.get("key");
  const resType = params.get("resType");
  return widgetSetupId && key && resType; // Check if all required parameters exist
};

const WidgetWrapper = () => {
  // Here we can extract the search from window location
  const search = window.location.search;

  return hasValidQueryParams(search) ? <WidgetDynamicLoading /> : <Navigate to="/" />;
};
export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Provider store={store}>
          <App />
        </Provider>
      </Suspense>
    ),
    children: [
      {
        path: "/",
        element: <Navigate to="/" />, // Redirect to base path
      },
      {
        path: "widget",
        element: <WidgetWrapper />, // Use the wrapper component to check for query params
        children: [
          {
            path: "/widget/successFeedback",
            element: <SuccessScreen />,
          }
        
        ],
      },
      {
        path: "/cancelReservation/:key",
        element: <CancelReservation />,
      },
      {
        path: "bookNow",
        element: <BookNowForm />,
      },
      {
        path: "paymentStatus",
        element: <PaymentSuccess />,
      },
      {
        path: "paymentStatusfail",
        element: <Paymentfailure />,
      },
      {
        path: "paymentpending",
        element: <PaymentPending />,
      },
    ],
  },
]);
function App() {
  const { t, i18n } = useTranslation();
  return (
    <ThemeProvider theme={theme} >
      <CssBaseline />
      <Outlet />
    </ThemeProvider>
  );
}

export default App;
