import { Box, InputLabel, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAddFields } from '../../store/slice/Addguest.slice';

const TextAreaComponent = ({ label = '', name, style = {}, placeText = '',setField,required}) => {
  const dispatch = useDispatch();
  const { addFields } = useSelector((store) => store?.feedback);
//console.log(addFields)
  // Local state to manage the input value
  const [value, setValue] = useState('');

  // Effect to update the local value based on existing addFields
  useEffect(() => {
    const existingField = addFields.find(field => field.name === name);
    if (existingField) {
      setValue(existingField.data); // Set initial value if exists
    }
  }, [addFields, name]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue); // Update local state
    // Check if the field already exists in the addFields array
    const updatedFields = addFields.map(field => {
      if (field.name === name) {
        // If the field exists, update its data
        return { ...field, data: newValue,required:required };
      }
      return field; // Return the existing field if not matching
    });

    // If the field didn't exist, we need to add it
    if (!addFields.some(field => field.name === name)) {
      updatedFields.push({ idFields: updatedFields.length + 1, name, data: newValue,required:required });
    }
    console.log("update details",updatedFields)

    // Dispatch the updated fields
    dispatch(setField(updatedFields)); // Use the correct action for updating fields
  };

  return (
    <Box>
      <InputLabel sx={{ mt: 2, mb: 1, fontSize: "10px", color: '#969BA0' }}>
        {label}
      </InputLabel>
      <TextField
        name={name}
        variant="outlined"
        value={value}  // Controlled input value
        fullWidth
        placeholder={placeText}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            backgroundColor: '#FDFDFD',
            '& fieldset': {
              borderColor: '#EBEBEB',
            },
            '&:hover fieldset': {
              borderColor: '#EBEBEB',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#0B5753',
            },
            '& input': {
              outline: 'none',
            },
            width: style.width || '100%',
            height: style.height || '150px',
          },
        }}
        multiline
        rows={4}
        onChange={handleChange} // Update local state and dispatch action
      />
    </Box>
  );
};

export default TextAreaComponent;

