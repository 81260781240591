import { Box, Typography } from "@mui/material";
import React from "react";
import clock from "../../assets/clock.png";
import user from "../../assets/users.png";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import widgetSettingSlice from "../../store/slice/widgetSetting.slice";

const YourBookingCard = ({bowling={}}) => {
  dayjs.extend(advancedFormat);
  const {totalCount,selectedDate,reservType,time,reservGroup,adult,child,kid,baby,toddler,reservationGroupData,selectedExtraProduct
    } = useSelector((store) => store.addGuest); 

    const {
        WidgetSetting,
        key
    } = useSelector((store) => store?.widgetSettings);

  const originalDate = dayjs(selectedDate, 'DD-MM-YYYY');
  const formattedDate = originalDate?.format('dddd DD MMMM YYYY');
        
    return (
    <Box
      sx={{
        minHeight: "282px",
        border: "1px dashed #0B5753",
        backgroundColor: "#F7FFFF",
        borderRadius: "16px",
        p: 2,
        width:"100%"
      }}
    >
      <Typography
        sx={{ color: "#0B5753", fontSize:{md:"24px",sm:"20px",xs:'16px'},fontWeight:"medium" }}
            >
                {WidgetSetting?.find((item) => item?.tag == 'reservationDetails').data}
          </Typography>
          <Box sx={{ mt: 2 }}>
        <Typography
          sx={{ color: "#000000", fontWeight: "medium", fontSize: {md:"22px",sm:"19px",xs:'16px'} }}
        >
                    {WidgetSetting?.find((item) => item?.tag == 'companyName').data}
        </Typography>
        <Typography sx={{ color: "#969BA0", fontSize: "10px" }}>
                    {WidgetSetting?.find((item) => item?.tag == 'companyAddress').data}  {WidgetSetting?.find((item) => item?.tag == 'companyCity').data}
        </Typography>
      </Box>
     
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          mt: 4,
          mb: 2,
        }}
      >
        <Box sx={{ width: "24px", height: "24px" }}>
          <img
            style={{ width: "100%", height: "100%" }}
            src={clock}
            alt="time"
          />
        </Box>
        <Typography sx={{ color: "#969BA0", fontSize: "14px" }}>
                  {formattedDate} {time.substring(0, 5)}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <Box sx={{ width: "24px", height: "24px" }}>
          <img
            style={{ width: "100%", height: "100%" }}
            src={user}
            alt="time"
          />
        </Box>
        <Typography sx={{ color: "#969BA0", fz: "14px" }}>
        {adult > 0 && `${adult} Adult`}
      {kid > 0 && `, ${kid} Kid`}
      {child > 0 && `, ${child} Child`}
      {toddler > 0 && `, ${toddler} Toddler`}
      {baby > 0 && `, ${baby} Baby`}
        </Typography>
      </Box>
      {selectedExtraProduct.length > 0 && <Box sx={{ mt: 2 }}>
        <Box sx={{display:'flex',gap:'0.6rem',alignItems:'center'}}>
          <ProductionQuantityLimitsIcon sx={{ color: "#969BA0" }} />
          <Typography
          sx={{ color: "#000000", fontWeight: "medium", fontSize: {md:"16px"} }}
        >
         Extra Products
        </Typography>
        </Box>
              {
  selectedExtraProduct?.length > 0 && (
    <Typography sx={{ color: "#969BA0", fontSize: "14px",ml:4,mt:1 }}>
      {selectedExtraProduct.map((item, index) => (
          <span key={item.idproduct}>
              {item.quantity}x {item.productName}
          {index < selectedExtraProduct.length - 1 && <br />} {/* Add comma except for the last item */}
        </span>
      ))}
    </Typography>
  )
}
        
     
      </Box>}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 4,
        }}
      >
        <Typography
          sx={{ fontSize: "16px", color: "#0B5753", fontWeight: "bold" }}
        >
         {reservType?.name}
        </Typography>
        <Typography
          sx={{ fontSize: "16px", color: "#000000", fontWeight: "bold" }}
                >
                    €{parseFloat(reservType?.totalCost).toFixed(2)}
        </Typography>
      </Box>
     {Object.keys(bowling).length > 0 &&  <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 1,
        }}
      >
        <Typography
          sx={{ fontSize: "16px", color: "#0B5753", fontWeight: "bold" }}
        >
        {bowling?.name}
        </Typography>
        <Typography
          sx={{ fontSize: "16px", color: "#000000", fontWeight: "bold" }}
        >
        {bowling.value}
        </Typography>
      </Box>}
    </Box>
  );
};

export default YourBookingCard;
