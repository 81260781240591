import { configureStore } from '@reduxjs/toolkit'
import addGuestReducer from './slice/Addguest.slice'
import widgetSettingReducer from "./slice/widgetSetting.slice"
import feedbackReducer from "./slice/feedback.slice"

export const store = configureStore({
  reducer: {
    addGuest: addGuestReducer, // Assign a proper name for the reducer
    widgetSettings:widgetSettingReducer,
    feedback:feedbackReducer
  },
})