import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { getWidgetPositionStyle } from "../../utils/utils";

import { useDispatch, useSelector } from "react-redux";
import { Paper, Snackbar, StepConnector, useMediaQuery, useTheme } from "@mui/material";
import YourDetails from "./YourDetails";

import backicon from "../../assets/backwidget.png";
import nexticon from "../../assets/next.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from "dayjs";
import FeedbackDetails from "./FeedbackDetails";
import { createEnquete, getFeedback } from "../../api/feedback.service";
import { setAddFields, updateFeedbackDetails } from "../../store/slice/feedback.slice";
import { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
dayjs?.extend(customParseFormat); // Extend for custom date format parsing

const DottedStepConnector = (props) => (
  <StepConnector
    {...props}
    sx={{
      "& .MuiStepConnector-line": {
        borderColor: "#000000", // Line color
        borderStyle: "dashed", // Dashed line style (simulates dotted with spacing)
        borderWidth: 1, // Line thickness
      },
    }}
  />
);

export default function FeedbackComponent({
  widgetSetupId,
  buttonPosition,
  resType,
  widgetPosition
}) {
  const dispatch = useDispatch();
  const {
    feedBackDetails,
    addFields,
  } = useSelector((store) => store?.feedback); 
  const {
    WidgetSetting,
    key
  }=useSelector((store) => store?.widgetSettings)
  const [openWidget,setOpenWidget]=React.useState(false)
  const navigate=useNavigate()
  const [steps, setSteps] = React.useState([
  "Feedback",
  "Your Detail",
  ]);
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({ 0: false });
  const totalSteps = () => steps.length;
  const completedSteps = () => Object.keys(completed).length;
  const isLastStep = () => activeStep === totalSteps() - 1;
  const allStepsCompleted = () => completedSteps() === totalSteps();


  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
   
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    setCompleted({
      ...completed,
      [activeStep]: true,
    });
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const fetchFeedbackDetails=async()=>{
    console.log('Fetching feedback')
    try {
      const response =await getFeedback(key)
      dispatch(updateFeedbackDetails(response?.data))
      console.log(response)
      
    } catch (error) {
      console.log("Failed to fetch feedback details", error);    
    }
  }

  const handleCreteFeedback=async()=>{
    const payload={
      data:{
        idcompany:parseInt(feedBackDetails?.idcompany),
        idreservation:3291,
        idenquete:parseInt(feedBackDetails?.idenquete),
        idendquetefield:parseInt(feedBackDetails?.enqueteList?.idenquetefield) ?? 0,
        data:addFields
      }
    }
    console.log('Creating feedback',payload)
    try {
      const response = await createEnquete(payload)
      console.log(response)
    } catch (error) {
      console.log("Failed to create feedback", error);
    }
  }


  useEffect(()=>{
    fetchFeedbackDetails()
  },[])



  return (
    <>
 
    <Box
    sx={{
      width: "100%",
      height:openWidget ? 'auto' : '100vh',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    }}
    >
           <Button
        sx={{
          position: "absolute",
          ...getWidgetPositionStyle(buttonPosition, true),
          m: 2,
        }}
        onClick={() => setOpenWidget(!openWidget)}
      >
       Feedback
      </Button>
   { openWidget && <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',width:"100%",m:3,p:3}}>
            <Paper
              elevation={1}
              sx={{
                backgroundColor: "#fff",
                px: {md:2,sm:1,xs:1},
                pt: 5,
                py: 3,
                width:{md:1197,sm:"100%",xs:"100%"},
                height:'auto'
        
              }}
            >
              <Box
                sx={{
                
                  overflow: "hidden", // Prevent overflow
                  px: 2,
                  mb: 5,
                  display:"flex",
                  justifyContent:'center'
                
                }}
              >
                <Box sx={{width:{md:"30%",sm:'100%',xs:'100%'}}}>
                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  connector={<DottedStepConnector />}
                  sx={{
                    flexWrap: { xs: "wrap", sm: "nowrap" }, // Wrap steps on small screens
                    justifyContent: { xs: "center", sm: "flex-start" }, // Center on xs and align to start on sm+
                  }}
                >
                  {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                      <StepButton
                        color="inherit"
                        sx={{
                          ...(activeStep === index && {
                            "& .MuiStepLabel-label": {
                              color: "#000000",
                              fontWeight: "bold",
                              fontSize: "15px",
                            }, // Highlighted label color
                          }),
                          // Adjust the width and font size for smaller screens
                          width: { xs: "100%", sm: "auto" }, // Full width on xs
                          fontSize: { xs: "12px", sm: "inherit" }, // Smaller font size on xs
                          //border: isSmallScreen ? "none" : `1px dashed ${activeStep === index ? "#0B5753" : "#EBEBEB"}`, // Remove border on small screens
                        }}
                        onClick={handleStep(index)}
                      >
                        {isSmallScreen ? "" : label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                </Box>
              </Box>
              <div>
                {allStepsCompleted() ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you're finished
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Box>
                      {/* Switch statement for rendering components based on active step */}
                      {(() => {
                        switch (activeStep) {
                          case 0:
                            return <FeedbackDetails
                             />
                          case 1:
                            return <YourDetails />;
                          default:
                            return <div>No page found</div>; // In case of unexpected value
                        }
                      })()}
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        variant="contained"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{
                          mr: 1,
                          backgroundColor: "#969BA0",
                          color: "white",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img src={backicon} alt="back-img" />
                          Previous
                        </Box>
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      {activeStep !== 4 && (
                        <Button
                          onClick={() => {
                            if(activeStep == 1){
                              handleCreteFeedback(); // Create feedback if step 1 is completed
                             // navigate("/widget/successFeedback"); // Navigate to thank you page if step 1 is completed
                            }
                              handleNext(); // Proceed to the next step otherwise
                          }}
                          sx={{
                            mr: 1,
                            backgroundColor:activeStep == 1  ?  "#F37120" : "#0B5753",
                            color: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img src={nexticon} alt="next-img" />
                            {activeStep == 1 ? "Send" : "Next"}
                          </Box>
                        </Button>
                      )}
                    </Box>
                  </React.Fragment>
                )}
              </div>
            </Paper>
            </Box>}
    </Box>
 
    </>
  );
}
