import { Box, Checkbox, FormControlLabel, FormGroup, Typography, Grid } from '@mui/material';
import React from 'react';
import Rating from '@mui/material/Rating';
import { useSelector } from 'react-redux';

const YourDetails = () => {
  const {addFields}=useSelector((store)=>store?.feedback)
  return (
    
    <Box sx={{ padding: { xs: 2, sm: 3, md: 4 } }}> {/* Responsive padding */}
    {
      addFields.map((item,index)=>(
        <Box key={item?.idFields}>
            <Box>
        <Typography sx={{ color: "#0B5753", fontSize: { xs: "20px", sm: "24px", md: "28px" }, fontWeight: "medium" }}>
          Here’s Your Feedback for Restaurant
        </Typography>
        <Typography sx={{ color: "#969BA0", fontSize: { xs: "12px", sm: "14px", md: "15px" }, fontWeight: "regular", mt: 2 }}>
        Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. Molestie mattis adipiscing sit condimentum. Pellentesque donec suscipit amet rhoncus eget ac purus. Nec diam mauris et aliquet sit commodo sagittis.
        </Typography>
      </Box>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={6}> {/* Full width on xs, half width on sm and md */}
          <Box
            sx={{
              borderRadius: "10px",
              border: "1px solid #EBEBEB",
              backgroundColor: "#FDFDFD",
              p: 2,
              height: "100%",
            }}
          >
            <Box sx={{ my: 1 }}>
              <Typography sx={{ color: "#000000", fontSize: { xs: "18px", sm: "20px" }, fontWeight: "medium" }}>{`${item?.rating}/5`}</Typography>
              <Typography sx={{ color: "#F37120", fontSize: { xs: "16px", sm: "18px" }, fontWeight: "medium" }}>
               {item?.label}
              </Typography>
            </Box>
            <Box>
              <Rating name="read-only" value={item?.rating}  precision={0.5} readOnly sx={{ gap: { xs: 2, sm: 3, md: 5 } }} />
              <Typography sx={{
                color: "#969BA0",
                fontSize: { xs: "14px", sm: "16px" },
                fontWeight: "regular",
                mt: 2,
                mb: 1
              }}>
                {item?.data}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6}> {/* Full width on xs, half width on sm and md */}
          <Box
            sx={{
              borderRadius: "10px",
              border: "1px solid #EBEBEB",
              backgroundColor: "#FDFDFD",
              p: 2,
              height: "100%",
            }}
          >
            <Typography sx={{ color: "#000000", fontSize: { xs: "20px", sm: "24px" }, fontWeight: "medium", mb: 2 }}>Your Details</Typography>
            <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ color: "#000000", fontSize: { xs: "14px", sm: "16px" }, fontWeight: "medium", flexGrow: 1 }}>Name</Typography>
              <Typography sx={{ color: "#969BA0", fontSize: { xs: "12px", sm: "15px" }, fontWeight: "regular", textAlign: 'left' }}>Martin</Typography>
            </Box>
            <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ color: "#000000", fontSize: { xs: "14px", sm: "16px" }, fontWeight: "medium", flexGrow: 1 }}>Email</Typography>
              <Typography sx={{ color: "#969BA0", fontSize: { xs: "12px", sm: "15px" }, fontWeight: "regular", textAlign: 'left' }}>martin@email.com</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ color: "#000000", fontSize: { xs: "14px", sm: "16px" }, fontWeight: "medium", flexGrow: 1 }}>Phone</Typography>
              <Typography sx={{ color: "#969BA0", fontSize: { xs: "12px", sm: "15px" }, fontWeight: "regular", textAlign: 'left' }}>(012) 1234-567</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

        </Box>
      ))
    }
    

      <Box sx={{ mt: 2 }}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox size="small" />}
            label={
              <Typography
                sx={{
                  color: "#969BA0",
                  fontSize: { xs: "12px", sm: "14px" },
                  lineHeight: "1.5rem",
                }}
              >
                Do you allow us to post your feedback on our website?
              </Typography>
            }
          />
          <FormControlLabel
            control={<Checkbox size="small" />}
            label={
              <Typography
                sx={{
                  color: "#969BA0",
                  fontSize: { xs: "12px", sm: "14px" },
                  lineHeight: "1.5rem",
                }}
              >
                Post Anonymously
              </Typography>
            }
          />
        </FormGroup>
      </Box>
    </Box>
  );
}

export default YourDetails;

