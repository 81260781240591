import { Box, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';

const PaymentCard = () => {
    const {totalCount,selectedDate,reservType,time,reservGroup,adult,child,kid,baby,toddler,reservationGroupData
    } = useSelector((store) => store.addGuest);
  return (
    <Box sx={{
        width:"100%",
    minHeight:"206px",
    border: "1px dashed #0B5753",
    backgroundColor: "#F7FFFF",
    borderRadius: "16px",
    mt:1,
    p: 2,}}
    >
        <Typography sx={{ color: "#0B5753", fontWeight: "medium", fontSize: {md:"24px",sm:"20px",xs:'16px'} }}>Payment</Typography>
        <Box>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:'center',mt:2,mb:2}}>
                <Typography sx={{color:"#000000",fontSize:'14px',fontWeight:"600"}}>Total</Typography>
                  <Typography sx={{ color: "#000000", fontSize: '14px', fontWeight: "600" }}>€{parseFloat((reservType?.totalCost /121) * 100).toFixed(2)}</Typography>
            </Box>
            <Box  sx={{display:"flex",justifyContent:"space-between",alignItems:'center',mb:2}}>
            <Typography sx={{color:"#000000",fontSize:'14px',fontWeight:"600"}}>VAT 21%</Typography>
                  <Typography sx={{ color: "#000000", fontSize: '14px', fontWeight: "600" }}>€{parseFloat((reservType?.totalCost / 121) * 21).toFixed(2)}</Typography>
            </Box>
            <Box sx={{borderTop:'1px solid #969BA0',display:'flex',justifyContent:'space-between',pt:2}}>
                <Typography sx={{fontSize:'18px',color:"#0B5753",fontWeight:"medium"}}>Total Including VAT</Typography>
                  <Typography sx={{ fontSize: '18px', color: "#0B5753", fontWeight: "medium" }}>€{parseFloat(reservType?.totalCost).toFixed(2)}</Typography>
            </Box>
        </Box>

       
      </Box>
  )
}

export default PaymentCard
