// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00796b',  // Primary color
    },
    secondary: {
      main: '#ff7043',  // Secondary color
    },
    background: {
      default: '#f5f5f5',  // Background color
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderColor: '#ff7043',  // Border color for widgets
          borderWidth: '2px',
          borderStyle: 'solid',
          position: 'relative',    // Positioning widgets
          padding: '16px',
          margin: '16px',
        },
      },
    },
  },
  typography: {
    h5: {
      color: '#00796b',  // Apply the primary color to headings
    },
  },
});

export default theme;
