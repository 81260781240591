import { Box, Button, Card, Typography } from '@mui/material';
import React from 'react';
import pending from "../../assets/pending.png";
import backicon from "../../assets/backwidget.png";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useNavigate } from 'react-router-dom';

const PaymentPending = () => {
    const navigate = useNavigate();
  
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '90vh',
        backgroundColor: '#fff', // Light background color
        border:"1pxx solid lightgrey",
        m:3
        
      }}>
        {/* Main content */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
        }}>
          <Box sx={{
            width: { xs: '60px', sm: '100px', md: '100px' }, // Responsive width
            height: { xs: '60px', sm: '100px', md: '100px' }, // Responsive height
            mb: 2,
          }}>
            <img src={pending} alt="failed-img" style={{ width: '100%', height: '100%' }} />
          </Box>
  
          <Typography sx={{
            color: "#F37120",
            fontSize: { xs: '24px', sm: '30px', md: '36px' }, // Responsive font size
            fontWeight: 600,
            textAlign: 'center',
            mb: 1,
          }}>
            Your Payment in Pending
          </Typography>
  
          <Typography sx={{
            color: "#969BA0",
            fontSize: { xs: '12px', sm: '14px', md: '16px' }, // Responsive font size
            textAlign: 'center',
            maxWidth: '600px', // Limit text width for better readability
            mb: 3,
          }}>
            Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. Molestie mattis adipiscing sit condimentum.
          </Typography>
          <Box sx={{mt:2}}>
            
          <Button
            sx={{
              backgroundColor: "#0B5753",
              color: "white",
              fontSize: "12px",
              px:2,
              py:1

            }}
          >
            Confirm & Pay
          </Button>
          </Box>
        </Box>
  
        {/* Bottom buttons */}
        <Box sx={{
          display: 'flex',
          justifyContent: { xs: 'center', sm: 'space-between' },
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          width: '100%',
          maxWidth: '1000px', // Keep buttons aligned within the main container's width
          mb: 2, // Margin from the bottom of the viewport
          px: 3,
        }}>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: "#969BA0",
              color: "white",
              fontSize: "12px",
              display: 'flex',
              alignItems: 'center',
              mb: { xs: 2, sm: 0 }, // Margin for vertical stacking on small screens
            }}
          >
            <img src={backicon} alt="back-img" style={{ marginRight: '8px' }} />
            Go Back
          </Button>
  
          <Button
            sx={{
              backgroundColor: "#F37120",
              color: "white",
              fontSize: "12px",
            }}
            
          >
            Start Again
          </Button>
        </Box>
      </Box>
    );
};

export default  PaymentPending;

