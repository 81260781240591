import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Grid, Popper, Popover } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import dayjs from 'dayjs';
import 'dayjs/locale/en';  // Make sure locale is available for formatting
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedDate } from '../../store/slice/Addguest.slice';
import { getCalenderDatas } from '../../api/HorecaWidget.service';
import { useTranslation } from 'react-i18next';

const Calendar = ({isOpen,handleClose,Ref}) => {
  const { t, i18n } = useTranslation();
  const {totalCount} = useSelector((store) => store?.addGuest); 
  const {blockedDates} = useSelector((store) => store?.widgetSettings); 
  const{selectedDates}=blockedDates ?? '';
  const today = dayjs();  // Get the current date
  const [currentDate, setCurrentDate] = useState(today);
  const daysOfWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  const daysInMonth = currentDate.daysInMonth();
  const firstDayOfMonth = currentDate.startOf('month').day(); // Day of the week


  const isToday = (day) => dayjs().isSame(currentDate.date(day), 'day');
  const isPastDay = (day) => currentDate.date(day).isBefore(today, 'day');
  const isBlockedDate = (day) => selectedDates?.includes(currentDate?.date(day)?.format('YYYY-MM-DD')) ;
  const isSelected = (day) => currentDate.date(day).isSame(currentDate, 'day');
  const dispatch=useDispatch()

  const handlePrevMonth = () => {
    setCurrentDate(currentDate.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setCurrentDate(currentDate.add(1, 'month'));
  };

  const handleDateSelect = (day) => {
    if (!isPastDay(day) && isBlockedDate(day)) {
      setCurrentDate(currentDate.date(day));
      const selectedDate=currentDate.date(day).format('DD-MM-YYYY')
      dispatch(updateSelectedDate(selectedDate))
      handleClose()
    }
  };
  return (
   <Popover
   open={isOpen}
   anchorEl={Ref}
   onClose={handleClose}
   anchorOrigin={{
     vertical: 'bottom',
     horizontal: 'left',
   }}
   >
    <Box sx={{ width: {md:'430px',sm:"100%",xs:"100%"},mx:{sm:"1rem"}, textAlign: 'center',backgroundColor:"#fff",px:2,paddingBottom:2}}>
      {/* Header with arrows and year/month */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
        <IconButton onClick={handlePrevMonth}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" sx={{fontWeight:"bold"}}>
          {currentDate.format('YYYY')}
        </Typography>
        <IconButton onClick={handleNextMonth}>
          <ArrowForward />
        </IconButton>
      </Box>

      {/* Display Year and Month */}
      <Typography sx={{ marginBottom: 1,color:"#0B5753",fontSize:"24px",fontWeight:'bold'}}>{t(`months.${currentDate.format('MMMM')}`)}
</Typography>

      {/* Days of the week */}
      <Grid container spacing={1} mt={3}>
        {daysOfWeek.map((day, index) => (
          <Grid item xs={1.71} key={index}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{day}</Typography>
          </Grid>
        ))}
      </Grid>

      {/* Dates Grid */}
      <Grid container spacing={1} sx={{ marginTop: 1 }}>
        {/* Empty cells for days before the first day of the month */}
        {[...Array(firstDayOfMonth)].map((_, index) => (
          <Grid item xs={1.71} key={index} />
        ))}

        {/* Render all days of the month */}
        {[...Array(daysInMonth)].map((_, dayIndex) => {
          const day = dayIndex + 1;
          const isPast = isPastDay(day);
          const isSelectedDay = isSelected(day);
          const isNotBlocked = isBlockedDate(day);
          const isActive = !isPast && isNotBlocked;

          return (
            <Grid item xs={1.71} key={dayIndex}>
              <Box
                onClick={() => handleDateSelect(day)}
                sx={{
                  border: '2px dotted',
                  borderColor: isPast || !isNotBlocked ? '#969BA0' : '#0B5753',
                  color: isSelectedDay && isActive ? 'white' : isPast || !isNotBlocked ? 'grey' : 'black',
                  backgroundColor: isSelectedDay && isActive  ? '#0B5753' :isPast || !isNotBlocked ? '#FDFDFD' : '#F7FFFF',
                  cursor: isActive ? 'pointer' : 'default',
                  borderRadius: '8px',
                  padding: 1,
                  textAlign: 'center',
                  transition: 'background-color 0.2s ease',
                  width:"40px",
                  height:"40px",
                  fontWeight: isActive? 'bold' : 'normal',
                  lineHeight: '1',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Typography variant="body2" sx={{color:isPast || !isNotBlocked ? "#969BA0" : isSelectedDay && isActive ? "#fff" :  '#3E4954',fontSize:"22px"}}>
                  {day}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
    </Popover>
  );
};

export default Calendar;
