import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  feedBackDetails: [],
  addFields:[],

};
export const addGuestSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    updateFeedbackDetails: (state, action) => {
      state.feedBackDetails = action.payload;
    },
    setAddFields: (state, action) => {
        state.addFields = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
    updateFeedbackDetails,
    setAddFields,
} = addGuestSlice.actions;

export default addGuestSlice.reducer;