
import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  adult: 0,
  child: 0,
  kid: 0,
  baby: 0,
  toddler: 0,
  isGreaterThan10: {
    adult: false,
    child: false,
    kid: false,
    baby: false,
    toddler: false,
  },
  isBookNowOpen: false,
  totalCount:0,
  selectedDate:'',
  reservType:{},
  time:'',
  reservGroup:{},
  reservationTypeDatas:[],
  freeTablesData:[],
  reservationGroupData:[],
  GuestDetailsData:[],
  extraProductData:[],
  selectedExtraProduct:[],
  transactionDetails:{},
  guestFields:[],
  combinationWidgetData:[],
  selectedCombination:[],
  addFields:[],
  reservationLang:'',
  ReservationDetail:{}

}
export const addGuestSlice = createSlice({
  name: 'addGuest',
  initialState,
  reducers: {
    setGuestCount: (state, action) => {
      const { guestType, count } = action.payload;
      if (state[guestType] !== undefined) {
        state[guestType] = count;
      }
    },
    checkGuestLimit: (state, action) => {
      const { guestType, isGreaterThan10 } = action.payload;
      if (state.isGreaterThan10[guestType] !== undefined) {
        state.isGreaterThan10[guestType] = isGreaterThan10;
      }
    },
    toggleBookNowOpen: (state) => {
      state.isBookNowOpen =!state.isBookNowOpen;
    },
    updateTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    updateSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    updateReservationType: (state, action) => {
      state.reservType = action.payload;
    },
    updateTime: (state, action) => {
      state.time = action.payload;
    },
    updateReservationGroup: (state, action) => {
      state.reservGroup = action.payload;
    },
    setReservationTypeDatas: (state, action) => {
      state.reservationTypeDatas = action.payload;
    },
    setReservationGroupDatas: (state, action) => {
      state.reservationGroupData = action.payload;
    },
    setFreeTablesData: (state, action) => {
      state.freeTablesData = action.payload;
    },
    setGuestDetailsData: (state, action) => {
      state.GuestDetailsData = action.payload;
    },
    setExtraProductData: (state, action) => {
      state.extraProductData = action.payload;
    },
    setSelectedExtraProduct: (state, action) => {
      state.selectedExtraProduct = action.payload;
    },
    setTransactionDetails: (state, action) => {
      state.transactionDetails = action.payload;
    },
    setGuestFields: (state, action) => {
      state.guestFields = action.payload;
    },
    setCombinationWidgetData: (state, action) => {
      state.combinationWidgetData = action.payload;
    },
    setSelectedCombination: (state, action) => {
      state.selectedCombination = action.payload;
    },
    setAddFields: (state, action) => {
      state.addFields = action.payload;
    },
    setReservationLang: (state, action) => {
      state.reservationLang = action.payload;
    },
    setReservationDetail:(state, action) => {
      state.ReservationDetail = action.payload;
    }
    // Add more reducers as needed for other parts of your application  // For example, to handle other state changes related to guests, reservations, etc.  // Remember to follow the same pattern as the setGuestCount and checkGuestLimit reducers.  // For example:
    
  },
})

// Action creators are generated for each case reducer function
export const {
  setGuestCount,
  checkGuestLimit,
  toggleBookNowOpen,
  updateTotalCount,
  updateSelectedDate,
  updateReservationType,
  updateTime,
  updateReservationGroup,
  setReservationTypeDatas,
  setFreeTablesData,
  setReservationGroupDatas,
  setGuestDetailsData,
  setExtraProductData,
  setSelectedExtraProduct,
  setTransactionDetails,
  setGuestFields,
  setCombinationWidgetData,
  setSelectedCombination,
  setAddFields,
  setReservationLang,
  setReservationDetail
  // Add more action creators as needed for other parts of your application  // For example, to handle other actions related to guests, reservations, etc.  // Remember to follow the same pattern as the setGuestCount and checkGuestLimit action creators.  // For example:
} = addGuestSlice.actions

export default addGuestSlice.reducer;
