import { Box, Button, Popover, Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import restype from "../../assets/restype.png";
import { useDispatch, useSelector } from "react-redux";
import { setReservationGroupDatas, updateReservationGroup } from "../../store/slice/Addguest.slice";
import { getReservationGroup } from "../../api/HorecaWidget.service";
import dayjs from "dayjs";

const ReservationGroup = ({ isOpen, handleClose, Ref }) => {
  const{reservType,selectedDate,time,reservationGroupData,reservGroup}=useSelector((store)=>store?.addGuest);
  const {
    key
  }=useSelector((store) => store?.widgetSettings)
  const dispatch=useDispatch()
  const fetchReservationGroupDetails=async()=>{
    const parsedDate = dayjs(selectedDate, 'DD-MM-YYYY');
    const formattedDate = parsedDate.format('YYYY-MM-DD');
    const [hours, minutes, seconds] = time.split(':');
    const date = new Date();
    date.setHours(hours, minutes, seconds, 0); // Set hours, minutes, and seconds
    const formattedTime = date.toTimeString().slice(0, 8); // Extracts HH:mm:ss
    const payload={
       idreservationtype:parseInt(reservType.idreservationtype) ?? 0,
       date :'2024-11-10',
       key:key,
       time: formattedTime
    }
    try {
      const response =await getReservationGroup(payload);
      dispatch(setReservationGroupDatas(response)); 
      console.log(response,"reservation group ") 
    } catch (error) {
      console.log("error fetching reservation group : ",error?.message) 
    }
  }

  useEffect(()=>{
    if(Object.keys(reservType).length > 0 ){
      fetchReservationGroupDetails()  // Fetch reservation group details when reservation type, selected date and time are available
    }
  },[reservType,time])
  useEffect(()=>{
    if(reservationGroupData?.length ==1){
      dispatch(updateReservationGroup(reservationGroupData[0]))  // Update reservation group details when reservation group data is available
    }

  },[reservationGroupData])

  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          width: { xs: "100%", sm: "100%", md: "440px" }, // Responsive width
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {reservationGroupData?.map((item, i) => (
          <Box
            key={i}
            sx={{
              display: "flex",
              gap: "0.5rem",
              backgroundColor: reservGroup?.idarrangementtype === item?.idarrangementtype ? "#0B5753" : "#EBEBEB",
              border: "1px solid #EBEBEB",
              p: 1,
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={() => {

              dispatch(updateReservationGroup(item))
            }}
          >
            <Grid container alignItems="center">
              {/* Image section */}
              <Grid item xs={4} sm={3} md={3} sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ width: "100px", height: "110px" }}>
                  <img src={restype} alt="res-type-img" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                </Box>
              </Grid>

              {/* Content section */}
              <Grid item xs={8} sm={9} md={9}>
                <Box sx={{ p: 1 }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography
                      sx={{
                        color: reservGroup?.idarrangementtype === item?.idarrangementtype ? "#FEF5D8" : "#000000",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      {item?.name}
                    </Typography>
                    
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 1 }}>
                    <Typography
                      sx={{
                        fontSize: "8px",
                        color: reservGroup?.idarrangementtype === item?.idarrangementtype ? "#FEF5D8" : "#969BA0",
                        flexGrow: 1,
                      }}
                    >
                     {item?.info}
                    </Typography>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        backgroundColor: reservGroup?.idarrangementtype === item?.idarrangementtype ? "#fff" : "#0B5753",
                        color: reservGroup?.idarrangementtype === item?.idarrangementtype ? "#0B5753" : "#fff",
                        width: "60px",
                        height: "30px",
                        fontSize: "10px",
                        borderRadius: "7px",
                        ml: 1, // Added margin for spacing
                      }}
                    >
                      Choose
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    </Popover>
  );
};

export default ReservationGroup;

