import { Box, InputLabel, TextField } from '@mui/material';
import React from 'react';

const TextInput = ({ label, name, style = {}, value, onChange, error, helperText }) => {
//  console.log("label",label)
 // console.log(value,error,helperText)
  return (
    <Box sx={{ width: '100%' }}> {/* Ensuring the parent Box is full width */}
      <InputLabel
        sx={{ mb: 2, fontWeight: 'bold', fontSize: '15px', color: '#000000' }}
        htmlFor={name} // Add 'htmlFor' for accessibility
      >
        {label}
      </InputLabel>
      <TextField
  id={name}
  name={name}
  variant="outlined"
  fullWidth
  sx={{
    '& .MuiOutlinedInput-root': {
      height: '40px', // Set height
      borderRadius: '8px', // Set border radius
      backgroundColor: '#FDFDFD', // Background color
      '& fieldset': {
        borderColor: error ? '#f44336' : '#EBEBEB', // Show red border if error
      },
      '&:hover fieldset': {
        borderColor: '#EBEBEB', // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#EBEBEB', // Change border color when focused
      },
      '& input': {
        outline: 'none', // Remove outline
      },
    },
    ...style, // Allow additional styling from props
  }}
  value={value}
  onChange={onChange}
  error={Boolean(error)} // Ensure error is boolean
  helperText={error && `${name} is ${helperText.toLowerCase()}!`} // Display the error or helper message
/>

    </Box>
  );
};

export default TextInput;
