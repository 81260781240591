import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Popover, Box, Typography, Button, Grid, Skeleton } from '@mui/material';
import restype from "../../assets/restype.png";
import { updateReservationType } from '../../store/slice/Addguest.slice';

const ReservationType = ({ Ref, isOpen, handleClose, isLoading }) => {
  const { reservationTypeDatas, reservType } = useSelector((store) => store?.addGuest);
  const dispatch = useDispatch();

  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          width: { xs: '100%', sm: '100%', md: '440px' }, // Full width for xs and sm, fixed width for md and above
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        {isLoading ? (
          // Shimmer loading effect
          Array.from(new Array(3)).map((_, i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                gap: '0.5rem',
                backgroundColor: '#EBEBEB',
                border: '1px solid #EBEBEB',
                p: 1,
                borderRadius: '8px',
                width: '100%',
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={4}>
                  <Skeleton variant="rectangular" height={110} />
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Box sx={{ p: 1, width: '100%' }}>
                    <Skeleton />
                    <Skeleton width="60%" />
                    <Skeleton width="80%" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))
        ) : (
          // Actual content
          reservationTypeDatas?.length <= 0 ? (
            <Typography variant="body2" sx={{ textAlign: 'center', color: 'grey', fontWeight: 'medium' }}>
              No reservation type available.
            </Typography>
          ) : (
            reservationTypeDatas.map((item, i) => (
              <Box
                key={i}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                  gap: '0.5rem',
                  backgroundColor: reservType?.name === item?.name ? '#0B5753' : '#EBEBEB',
                  border: '1px solid #EBEBEB',
                  p: 1,
                  borderRadius: '8px',
                  cursor: 'pointer',
                  width: '100%',
                }}
                onClick={() => {
                  dispatch(updateReservationType(item));
                  handleClose();
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box
                      sx={{
                        width: '100%',
                        height: { xs: 'auto', md: '110px' },
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        src={restype} // Ensure to use the correct source here
                        alt="res-type-img"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Box sx={{ p: 1, width: '100%' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography
                          sx={{
                            color: reservType?.name === item?.name ? '#FEF5D8' : '#000000',
                            fontSize: '14px',
                            fontWeight: '600',
                          }}
                        >
                          {item?.name}
                        </Typography>
                        {item?.totalCost > 0 && (
                          <Typography
                            sx={{
                              color: reservType?.name === item?.name ? '#FEF5D8' : '#E50914',
                              fontSize: '14px',
                              fontWeight: '600',
                            }}
                          >
                            €{item?.totalCost}
                          </Typography>
                        )}
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '8px',
                          color: reservType?.name === item?.name ? '#FEF5D8' : '#969BA0',
                          mt: 1,
                        }}
                      >
                        {item?.info}
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            backgroundColor: reservType?.name === item?.name ? '#fff' : '#0B5753',
                            color: reservType?.name === item?.name ? '#0B5753' : '#fff',
                            width: '60px',
                            height: '30px',
                            fontSize: '10px',
                            borderRadius: '7px',
                          }}
                        >
                          Choose
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))
          )
        )}
      </Box>
    </Popover>
  );
};

export default ReservationType;
