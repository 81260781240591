import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, InputLabel } from '@mui/material';


export default function DatePickerComponent({value, label, name,onChange,error,helperText}) {
    const [values,setValues]=React.useState('')
  

  

  return (
    <Box sx={{ mt: 1, width: '100%' }}>
      <InputLabel
        sx={{ mb: 2, fontWeight: 'bold', fontSize: '15px', color: '#000000', mt: 1 }}
        htmlFor={name} // Add 'htmlFor' for accessibility
      >
        {label}
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            name={name}
            value={value}
            onChange={
                (newValue) => {
                  setValues(newValue);
                
                }
            }
            fullWidth // Ensure the DatePicker takes full width
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
}
