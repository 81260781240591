import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { countries } from '../../utils/countryList';
import { InputLabel } from '@mui/material';

export default function CountrySelect({style={},...props}) {
  return (
    <Box>
         <InputLabel sx={{mb:2,fontWeight:'bold',fontSize:"15px",color:'#000000'}}>
   {props?.label}
      </InputLabel>
    <Autocomplete
      id="country-select-demo"
      sx={{ width: '100%' }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...optionProps}
          >
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=""
            />
            {option.label} ({option.code}) +{option.phone}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined" // Keep the outline variant
          
          slotProps={{
            htmlInput: {
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            },
          }}
          name={props?.name}
          placeholder='Select country'
          sx={{
            '& .MuiOutlinedInput-root': {
              height: '40px', // Set height
              borderRadius: '8px', // Set border radius
              backgroundColor: '#FDFDFD', // Background color
              '& fieldset': {
                borderColor: '#EBEBEB', // Default border color (single border)
              },
              '& fieldset': {
                borderColor: '#EBEBEB', // Default border color
              },
              '&:hover fieldset': {
                borderColor: '#EBEBEB', // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: '#0B5753', // Change border color when focused
              },
              '& input': {
                outline: 'none', // Remove outline
              },
              width:style.width || '100%'
            },
          }}
        />
      )}
    />
    </Box>
  );
}



