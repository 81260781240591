import React, { startTransition, useEffect, useState } from "react";
import HorecaWidget from "./horecaWidget/HorecaWidget";
import ReservationWidget from "./reservationWidget/ReservationWidget";
import { Outlet, useLocation } from "react-router-dom";
import { getWidgetSetUpPosition } from "../api/HorecaWidget.service";
import { useDispatch, useSelector } from "react-redux";
import FeedbackComponent from "./feedbackWidget/FeedbackComponent";
import { updateButtonPosition, updateDefaultLanguage, updateKey, updateResType, updateWidgetPosition, updateWidgetSetupId, updateFloating } from "../store/slice/widgetSetting.slice";

const WidgetDynamicLoading = () => {
  const dispatch=useDispatch()
  const location = useLocation();
  const {
  widgetSetupId,
  defaultLanguage,
  key,
  buttonPosition,
  resType,
      widgetPosition,
  floating

  }=useSelector((store) => store?.widgetSettings)
  // State to hold widget parameters
  const fetchWidgetSetupPosition=async(widgetSetupId)=>{
    try {
      const response=await getWidgetSetUpPosition(widgetSetupId)
      startTransition(() => {
        dispatch(updateWidgetPosition(response?.widgetPosition));
      });
     // dispatch(updateWidgetPosition(response?.widgetPosition) ) 
    } catch (error) {
      console.error("Error fetching widget settings:", error?.message);  
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // Extract query parameters
    const setupId = queryParams.get("widgetsetupid");
    const keyParam = queryParams.get("key");
    const langParam = queryParams.get("lang");
    const buttonPositionParam = queryParams.get("buttonposition");
    const resTypeParam = queryParams.get("resType");
    const floatingParam = queryParams.get("floating");
    //getting widget position 
    fetchWidgetSetupPosition(setupId)

    // Update state with the extracted parameters
    dispatch(updateWidgetSetupId(setupId))
    dispatch(updateKey(keyParam))
    dispatch(updateDefaultLanguage(langParam))
    dispatch(updateButtonPosition(buttonPositionParam))
      dispatch(updateResType(resTypeParam))
      dispatch(updateFloating(floatingParam))

  }, [location]);
  // Conditional rendering based on widgetSetupId
 // Conditional rendering based on widget setup parameters
 if (widgetSetupId === '293' && key && resType === "horeca") {
  return (
    <div>
      <HorecaWidget
            widgetSetupId={widgetSetupId}
            buttonPosition={buttonPosition}
            resType={resType}
            widgetPosition={widgetPosition}
            floating={floating}

      />

    </div>
  );
} else if (widgetSetupId === '293' && key && resType === "Feedback") {
  return (
    <div>
      <FeedbackComponent 
             widgetSetupId={widgetSetupId}
             buttonPosition={buttonPosition || 'Bottom-Right'}
             resType={resType}
             widgetPosition={widgetPosition}
      />

    </div>
  );
} else {
  return (
    <div>
      {/* Outlet for nested routes */}
      <Outlet />
    </div>
  );
}
};


export default WidgetDynamicLoading;
