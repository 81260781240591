import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Grid2 from "@mui/material/Grid2";
import YourBookingCard from "./YourBookingCard";
import PaymentCard from "./PaymentCard";
import extraImg from "../../assets/extra.png";
import { getExtraProductDetails } from "../../api/HorecaWidget.service";
import { useDispatch, useSelector } from "react-redux";
import { setExtraProductData, setSelectedExtraProduct, updateReservationType } from "../../store/slice/Addguest.slice";

const Extra = () => {
  const dispatch = useDispatch();
  const { reservType, selectedExtraProduct, extraProductData } = useSelector((store) => store.addGuest);

  const [isShowMore, setIsShowMore] = useState(false);
  const [quantities, setQuantities] = useState({}); // Local state for quantities

  const productsToDisplay =
    extraProductData?.length > 0
      ? isShowMore
        ? extraProductData
        : extraProductData?.slice(0, 2)
      : [];

  const fetchExtraProducts = async () => {
    try {
      const response = await getExtraProductDetails(reservType?.idreservationtype);
      dispatch(setExtraProductData(response));
    } catch (error) {
      console.log("error extra product", error?.message);
    }
  };

  useEffect(() => {
    fetchExtraProducts();
  }, []);

  const handleProductSelect = (item) => {
    const isSelected = selectedExtraProduct?.some((product) => product.idproduct === item.idproduct);
    const currentQuantity = quantities[item.idproduct] || 0;
    const newQuantity = isSelected ? 0 : currentQuantity + 1;

    if (isSelected) {
      dispatch(setSelectedExtraProduct(selectedExtraProduct.filter((product) => product.idproduct !== item.idproduct)));
      const newTotalCost = reservType?.totalCost - item?.price * currentQuantity;
      dispatch(updateReservationType({ ...reservType, totalCost: newTotalCost }));
    } else {
      dispatch(setSelectedExtraProduct([...selectedExtraProduct, { ...item, quantity: newQuantity }]));
      const newTotalCost = reservType?.totalCost + item?.price * newQuantity;
      dispatch(updateReservationType({ ...reservType, totalCost: newTotalCost }));
    }

    setQuantities({ ...quantities, [item.idproduct]: newQuantity });
  };

  const handleQuantityChange = (item, increment) => {
    const currentQuantity = quantities[item.idproduct] || 0;
    const newQuantity = Math.max(currentQuantity + (increment ? 1 : -1), 0); // Prevent negative quantity
  
    setQuantities({ ...quantities, [item.idproduct]: newQuantity });
  
    const totalChange = (newQuantity - currentQuantity) * item.price;
    const newTotalCost = reservType.totalCost + totalChange;
    dispatch(updateReservationType({ ...reservType, totalCost: newTotalCost }));
  
    // Update selectedExtraProduct
    if (newQuantity === 0) {
      // Remove the item if quantity is zero
      dispatch(setSelectedExtraProduct(selectedExtraProduct.filter((product) => product.idproduct !== item.idproduct)));
    } else {
      // Update or add the item with the new quantity
      const updatedSelectedProducts = selectedExtraProduct.map((product) => {
        if (product.idproduct === item.idproduct) {
          return { ...product, quantity: newQuantity }; // Update quantity
        }
        return product; // Return other products unchanged
      });
  
      // If item was not previously selected, add it
      if (!updatedSelectedProducts.some((product) => product.idproduct === item.idproduct)) {
        updatedSelectedProducts.push({ ...item, quantity: newQuantity });
      }
  
      dispatch(setSelectedExtraProduct(updatedSelectedProducts));
    }
  };
  

  return (
    <Box>
      <Grid2 container spacing={2}>
        <Grid2 size={{ md: 7, sm: 12, xs: 12 }}>
          {productsToDisplay?.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: "1rem",
                border: "1px solid #EBEBEB",
                p: 2,
                borderRadius: "5px",
                backgroundColor: selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? "#0B5753" : "#FDFDFD",
                mb: 2,
                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  width: { md: "550px", sm: "300px", xs: "100%" },
                  height: { md: "165px", sm: "150px", xs: "200px" },
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={extraImg}
                  alt="extra-img"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Box>
              <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                  <Typography
                    sx={{
                      color: selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? "#fff" : "#000000",
                      fontSize: { md: "20px", sm: "14px" },
                      fontWeight: "semiBold",
                    }}
                  >
                    {item?.productName}
                  </Typography>
                  <Typography
                    sx={{
                      color: selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? "#fff" : "#0B5753",
                      fontSize: { md: "20px", sm: "14px" },
                      fontWeight: "semiBold",
                    }}
                  >
                    €{item?.price}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? "#FEF5D8" : "#969BA0",
                    fontSize: "12px",
                  }}
                >
                  {item?.productinformation}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center",justifyContent:'flex-end', mt: 2, gap: "1rem" }}>
                  <Button
                    onClick={() => handleQuantityChange(item, false)}
                    disabled={(quantities[item.idproduct] || 1) === 1} // Disable if quantity is 0
                    sx={{ minWidth: "30px",color:selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? "#fff" : "#000000" }}
                  >
                    -
                  </Button>
                  <Typography sx={{color:selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? "#fff" : "#000000"}}>{quantities[item.idproduct] || 1}</Typography>
                  <Button onClick={() => handleQuantityChange(item, true)} sx={{ minWidth: "30px",color:selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? "#fff" : "#000000" }}>
                    +
                  </Button>
                  <Button
                    onClick={() => handleProductSelect(item)}
                    sx={{
                      backgroundColor: selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? "#fff" : "#0B5753",
                      fontSize: "12px",
                      color: selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? "#0B5753" : "#fff",
                      width: "100px",
                    }}
                  >
                    {selectedExtraProduct?.some((product) => product.idproduct === item.idproduct) ? "Remove" : "Add"}
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}

          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button
              sx={{
                backgroundColor: "#0B5753",
                fontSize: "14px",
                color: "#fff",
                width: "140px",
                py: 1,
              }}
              onClick={() => setIsShowMore(!isShowMore)}
            >
              {isShowMore ? "Show Less" : "Show More"}
            </Button>
          </Box>
        </Grid2>
        <Grid2 size={{ md: 5, sm: 12, xs: 12 }}>
          <YourBookingCard />
          <PaymentCard />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Extra;


