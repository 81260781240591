import { Box, Typography, Grid } from '@mui/material';
import React from 'react';
import QuestionAnswerComponent from './QuestionAnswerComponent';
import TextAreaComponent from '../../components/Input/TextAreaComponent';
import { useSelector } from 'react-redux';
import { setAddFields } from '../../store/slice/feedback.slice';

const FeedbackDetails = (...props) => {
  const {feedBackDetails,addFields}=useSelector((store)=>store?.feedback)
  return (
    <Box sx={{ padding: { xs: 2, md: 4 } }}> {/* Responsive padding */}
      <Box sx={{ mb: 4 }}> {/* Margin bottom for spacing */}
        <Typography sx={{ color: "#0B5753", fontSize: { xs: "24px", md: "28px" }, fontWeight: "medium" }}>
          Thank You for Filling out our survey!
        </Typography>
        <Typography sx={{ color: "#969BA0", fontSize: { xs: "14px", md: "15px" }, fontWeight: "regular", mt: 2 }}>
          Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. Molestie mattis adipiscing sit condimentum. Pellentesque donec suscipit amet rhoncus eget ac purus. Nec diam mauris et aliquet sit commodo sagittis.
        </Typography>
      </Box>

      <Grid container spacing={2}> {/* Use Grid for responsiveness */}
        {feedBackDetails?.enqueteList?.map((item, index) => ( // Dynamic rendering of feedback sections
          <Grid item xs={12} key={index}> {/* Full width on extra-small screens */}
            <Grid container spacing={2} alignItems="center"> {/* Nested Grid for QuestionAnswer and TextArea */}
              <Grid item xs={12} md={6}> {/* QuestionAnswer takes half the width on medium and larger screens */}
                <QuestionAnswerComponent
                  data={item}
                  
                  />
              </Grid>
              <Grid item xs={12} md={6}> {/* TextArea takes half the width on medium and larger screens */}
              <TextAreaComponent
            style={{ width: '100%', height: '220px' }} 
            placeText='Type here ...'
            name={item?.name?.replace(/\s+/g, '')}
            setField={setAddFields}
            required={item?.required}
          /> 
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FeedbackDetails;

