import 'react-international-phone/style.css';
import './textinput.css';

import {
  Box,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';

export const MuiPhoneComponent = ({
  value,
  onChange,
  style = {},
  error,
  helperText,
  label,
  name,
  ...restProps
}) => {
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: 'us',
      value: value,
      countries: defaultCountries,
      onChange: (data) => {
        onChange(data.phone);
      },
    });

  return (
    <Box sx={{ width: '100%' }}>
      <Typography sx={{ mb: 2, fontWeight: 'bold', fontSize: '15px', color: '#000000' }}>
        {label} {/* Display label prop */}
      </Typography>
      <TextField
        id={name}
        variant="outlined"
        placeholder="Phone number"
        value={inputValue}
        onChange={handlePhoneValueChange}
        name={name} // Pass name prop
        type="tel"
        inputRef={inputRef}
        error={Boolean(error)} // Ensure error is a boolean
        helperText={Boolean(error) ? `phone is ${helperText}!` : ''} // Show helperText only when there's an error
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              style={{ marginRight: '2px', marginLeft: '-8px', width: '60px' }}
            >
              <Select
                MenuProps={{
                  style: {
                    height: '400px',
                    width: '360px',
                    top: '20px',
                    left: '-34px',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                sx={{
                  width: 'max-content',
                  fieldset: { display: 'none ' },
                  '&.Mui-focused fieldset': { display: 'none !important' },
                  '.MuiSelect-select': {
                    padding: '8px',
                    paddingRight: '24px !important',
                  },
                  svg: { right: 0 },
                }}
                value={country.iso2}
                onChange={(e) => setCountry(e.target.value)}
                renderValue={(value) => (
                  <FlagImage iso2={value} style={{ display: 'flex' }} />
                )}
              >
                {defaultCountries.map((c) => {
                  const country = parseCountry(c);
                  return (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      <FlagImage iso2={country.iso2} style={{ marginRight: '8px' }} />
                      <Typography marginRight="8px">{country.name}</Typography>
                      <Typography color="gray">+{country.dialCode}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </InputAdornment>
          ),
        }}
        {...restProps} // Spread the remaining props
        sx={{
          width: '100%', // Set width to 100%
          '& .MuiOutlinedInput-root': {
            height: '40px', // Set height
            borderRadius: '8px', // Set border radius
            backgroundColor: '#FDFDFD', // Background color
            '& fieldset': {
              borderColor: error ? '#f44336' : '#EBEBEB', // Change border color when error
            },
            '&:hover fieldset': {
              borderColor: '#EBEBEB', // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: '#0B5753', // Change border color when focused
            },
            '& input': {
              outline: 'none', // Remove outline
            },
          },
          ...style, // Allow additional custom styling
        }}
      />
    </Box>
  );
};

