import axios from "axios"
import apiEndpoints from "../config/apiEndpoints"

export const getLanguageDetails=async()=>{
    try {
        const response =await axios.get(apiEndpoints.GET_LANGUAGE)
        return response.data; 
    } catch (error) {
        console.error("Error fetching language details", error?.message);
        return null;     
    }
}

export const getWidgetSettings=async(language)=>{
    try {
        const response =await axios.get(apiEndpoints.GET_WIDGET_SETTING(language))
        return response.data; 
    } catch (error) {
        console.error("Error fetching Widget setting", error?.message);
        return null;     
    }
}


// GET_WIDGET_TITLE_AND_INFO
export const getWidgetTitleandInfo = async (setupID, language) => {
    try {
        const response = await axios.get(apiEndpoints.GET_WIDGET_TITLE_AND_INFO(setupID, language))
        return response.data;
    } catch (error) {
        console.error("Error fetching WIDGET_TITLE_AND_INFO", error?.message);
        return null;
    }
}

export const getWidgetSetUpPosition=async(ID)=>{
    try {
        const response =await axios.get(apiEndpoints.GET_WIDGET_POSITION(ID))
        return response.data; 
    } catch (error) {
        console.error("Error fetching Widget setup position", error?.message);
        return null;     
    }
}

export const getCalenderDatas=async(
    key,
    noOfGuests,
    widgetId,
    reservationRequestMin,
)=>{
    try {
        const response =await axios.get(apiEndpoints.GET_CALENDER_DETAILS(
            key,
            noOfGuests,
            widgetId,
            reservationRequestMin,
        ))
        return response.data; 
    } catch (error) {
        console.error("Error fetching calender details:", error?.message);
        return null;     
    }
}

export const getReservationType=async(data)=>{
    try {
        const response = await axios.post(apiEndpoints.GET_RESERVATION_TYPE, data)
        return response.data; 
    } catch (error) {
        console.error("Error fetching reservation type:", error?.message);
        return null;     
    }
}

export const getReservationGroup=async(data)=>{
    try {
        const response = await axios.post(apiEndpoints.GET_RESERVATION_GROUP_TYPE, data)
        return response.data; 
    } catch (error) {
        console.error("Error fetching reservation type:", error?.message);
        return null;     
    }
}

export const getFreeTables=async(data)=>{
    try {
        const response = await axios.post(apiEndpoints.GET_FREE_TABLES, data)
        return response.data; 
    } catch (error) {
        console.error("Error fetching Free tables data:", error?.message);
        return null;     
    }
}

export const getReservationTypeBasedOnProducts=async(
    key,
    id
)=>{
    try {
        const response =await axios.get(apiEndpoints.GET_PRODUCTS_RESERVATION_TUPE(
            key,
            id
        ))
        return response.data; 
    } catch (error) {
        console.error("Error fetching Product reservation details:", error?.message);
        return null;     
    }
}

export const getGuestDetails=async(key,ID,lang)=>{
    try {
        const response =await axios.get(apiEndpoints.GET_GUEST_DETAILS(key,ID,lang))
        return response.data; 
    } catch (error) {
        console.error("Error fetching guest details", error?.message);
        return null;     
    }
}

export const getExtraProductDetails=async(ID)=>{
    try {
        const response = await axios.get(apiEndpoints.GET_EXTRA_PRODUCTS_DETAILS(ID))
        return response.data; 
    } catch (error) {
        console.error("Error fetching extra product details", error?.message);
        return null;     
    }
}

export const transactionCostForReservation=async(key,ID)=>{
    try {
        const response = await axios.get(apiEndpoints.TRANSACTIONCOST_RESTYPE(key,ID))
        return response.data; 
    } catch (error) {
        console.error("Error fetching extra product details", error?.message);
        return null;     
    }
}

export const createReservation=async(data)=>{
    try {
        const response = await axios.post(apiEndpoints.CREATE_RESERVATION,data)
        return response.data; 
    } catch (error) {
        console.error("Error in creating reservation", error?.message);
        return null;     
    }
}

export const getReservationDetails=async(ID)=>{
    try {
        const response = await axios.get(apiEndpoints.GET_RESERVATION_DETAILS(ID))
        return response.data; 
    } catch (error) {
        console.error("Error fetching reservation details", error?.message);
        return null;     
    }
}

export const cancelReservation=async(key)=>{
    try {
        const response = await axios.delete(apiEndpoints.CANCEL_RESERVATION(key))
        console.log("response:",response?.status)
        return response.data; 
    } catch (error) {
        console.error("Error in cancelling the reservation", error?.message);
        return null;     
    }
}

export const fetchCombinationWidget=async(  
    idreservationtype,
    key,
    arrivaldate,
    arrivalTime,
    departureTime,
    totalPeople,
    lang)=>{
    try {
        const response = await axios.get(apiEndpoints.COMBINATION_WIDGET(
            idreservationtype,
            key,
            arrivaldate,
            arrivalTime,
            departureTime,
            totalPeople,
            lang
        ))
        return response.data; 
    } catch (error) {
        console.error("Error fetching extra product details", error?.message);
        return null;     
    }
}


