import axios from "axios";
import apiEndpoints from "../config/apiEndpoints";

export const getFeedback = async (key) => {
    // Convert the key to base64
    const updateKey = btoa(key);  // btoa() is used to encode to base64 in JavaScript
    
    try {
        const response = await axios.get(apiEndpoints.GET_FEEDBACK(updateKey));  // Use the base64-encoded key
        console.log("response:",response)
        return response.data;
    } catch (error) {
        console.error("Error fetching feedback", error?.message);
        return null;
    }
};


export const createEnquete=async(data)=>{
    try {
        const response = await axios.post(apiEndpoints.CREATE_FEEDBACK,data)
        return response.data; 
    } catch (error) {
        console.error("Error creating Feedback :", error?.message);
        return null;     
    }
}