import * as React from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import GradeRoundedIcon from "@mui/icons-material/GradeRounded";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const labels = {
  0.5: "Poor",
  1: "Poor",
  1.5: "Poor",
  2: "Poor",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function RatingComponent({ data, name, setField }) {
  const dispatch = useDispatch();
  const { addFields } = useSelector((store) => store?.feedback);
  const [value, setValue] = React.useState(0.5);
  const [hover, setHover] = React.useState(-1);

  // Effect to set initial value based on Redux state
  useEffect(() => {
    const existingField = addFields.find((field) => field.name === name);
    if (existingField) {
      setValue(existingField.rating); // Set initial value if exists
    }
  }, [addFields, name]);

  const handleChange = (newValue) => {
    setValue(newValue); // Update local state

    // Check if the field already exists in the addFields array
    const updatedFields = addFields.map((field) => {
      if (field.name === name) {
        // If the field exists, update its data
        return { ...field, rating: newValue,label:labels[newValue] };
      }
      return field; // Return the existing field if not matching
    });

    // If the field didn't exist, we need to add it
    if (!addFields.some((field) => field.name === name)) {
      updatedFields.push({
        idFields: updatedFields.length + 1,
        name,
        rating: newValue,
        label:labels[newValue]
      });
    }

    // Dispatch the updated fields
    dispatch(setField(updatedFields)); // Use the correct action for updating fields
  };

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "80%", md: "100%" },
        mx: "auto",
      }}
    >
      <Rating
        name={name}
        value={value}
        precision={0.5}
        getLabelText={getLabelText}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        icon={
          <GradeRoundedIcon
            style={{ width: 40, height: 40 }}
            fontSize="inherit"
          />
        }
        emptyIcon={
          <StarOutlineRoundedIcon
            style={{ opacity: 0.55, width: 40, height: 40 }}
            fontSize="inherit"
          />
        }
        sx={{
          mt: { xs: 3, sm: 5 },
          gap: { xs: 2, sm: 3, md: 5 },
        }}
      />
      {value !== null && (
        <Box
          sx={{
            display: "flex",
            justifyContent: hover <= 2.5 ? "flex-start" : "flex-end",
            alignItems: "center",
            mr: { xs: 1, md: 11 },
          }}
        >
          <Typography
            sx={{
              color: "#969BA0",
              fontSize: { xs: "10px", sm: "12px", md: "14px" },
            }}
          >
            {labels[hover !== -1 ? hover : value]}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
