import {
  Box,
  FormControl,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { checkGuestLimit, setGuestCount, updateTotalCount } from "../../store/slice/Addguest.slice";
import { useTranslation } from "react-i18next";

const Addguest = ({isOpen,handleClose,Ref}) => {
  const { t, i18n } = useTranslation();
  const {adult,baby,child,kid,toddler,isGreaterThan10 } = useSelector((store) => store.addGuest); 
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(updateTotalCount(adult+baby+child+toddler+kid))

  },[adult,baby,child,kid,toddler,])
  return (
    <Popover
    open={isOpen}
    anchorEl={Ref}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
  >
     <Box
      sx={{ backgroundColor: "#fff", width: "208px", height: "321px", pt: 2 }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mx:3,
          mb:1
        }}
      >
        <Typography
          sx={{ fontSize: "14px", color: "000000", fontWeight: "bold" }}
        >
          {t('main.field1')}
        </Typography>
        <Typography
          sx={{ fontSize: "14px", color: "000000", fontWeight: "bold" }}
        >
         {t('main.qty')}
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb:2,
            mx:3
          }}
        >
          <Typography sx={{fontSize:"12px",color:"#000000",fontWeight:"bold"}}>{t('ageCategories.Adult')}</Typography>
          <FormControl fullWidth sx={{ width: "72px", height: "32px" }}>
          {isGreaterThan10?.adult ?
                      <TextField
                      placeholder="00"
                      name="adult"
                      value={adult} // Show value adjusted for base of 10
                      type='number'
                      // Prevent the Select from closing
                      onClick={(e)=> e.stopPropagation()}
                      onFocus={(e)=> e.stopPropagation()}
                      sx={{
                        width: "100%",
                        height: "30px",
                        "& .MuiOutlinedInput-input": {
                          padding: 1,
                          color:'#9F9F9F',
                        },
                      }}
                      size="small"
                      onChange={(e) => {
                       // e.preventDefault()
                        const inputValue = e.target.value;
                        const newValue = Number(inputValue);
            
                        // Update state only if the input is a valid number
                        if (!isNaN(newValue) && inputValue.trim() !== "") {   
                          dispatch(setGuestCount({
                            guestType:"adult", count: newValue
                          }))
                        } else if (inputValue === "") {
                          dispatch(setGuestCount({
                            guestType:"adult", count: 0
                          }))
                        }
                      }}
                    />
                    :
                    <Select
                    id="demo-simple-select"
                    value={adult}
                    size="small"
                    IconComponent={KeyboardArrowDownIcon} // Custom arrow icon
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#9F9F9F", // Remove label border space
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#9F9F9F", // Remove hover border
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#9F9F9F", // Remove focus border
                      },
                      "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#9F9F9F", // Ensure outline is transparent
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: 1, // Remove padding if needed
                        color:'#9F9F9F',
                        fontSize:"12px"
                      },
                    }}
                    onChange={(event) => dispatch(setGuestCount({
                      guestType:"adult", count: Number(event.target.value)
                    }))}
                  >
                    {new Array(10).fill(null).map((_, index) => (
                      <MenuItem key={index} value={index + 1} sx={{color:'#9F9F9F',
                      fontSize:"12px"}}>
                        {index + 1}
                      </MenuItem>
                    ))}
                    <MenuItem
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mx: 0.5,
                      }}
                    >
                      <Typography
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent the Select from closing
                          dispatch(checkGuestLimit({
                            guestType:'adult', isGreaterThan10:!isGreaterThan10?.adult
                          }))
                        }}
                      >
                        +
                      </Typography>
                    </MenuItem>
                  </Select>
                }
         
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb:2,
            mx:3
          }}
        >
          <Typography sx={{fontSize:"12px",color:"#000000",fontWeight:"bold"}}>{t('ageCategories.Child')}</Typography>
          <FormControl fullWidth sx={{ width: "72px", height: "32px" }}>
          {isGreaterThan10?.child ?
                      <TextField
                      placeholder="00"
                      name="child"
                      value={child} // Show value adjusted for base of 10
                      // Prevent the Select from closing
                      type="number"
                      onClick={(e)=> e.stopPropagation()}
                      onFocus={(e)=> e.stopPropagation()}
                      sx={{
                        width: "100%",
                        height: "30px",
                        "& .MuiOutlinedInput-input": {
                          padding: 1,
                          color:'#9F9F9F',
                        },
                      }}
                      size="small"
                      onChange={(e) => {
                       
                       // e.preventDefault()
                        const inputValue = e.target.value;
                        const newValue = Number(inputValue);
            
                        // Update state only if the input is a valid number
                        if (!isNaN(newValue) && inputValue.trim() !== "") {
                          dispatch(setGuestCount({
                            guestType:"child", count: newValue
                          }))
                        } else if (inputValue === "") {
                          dispatch(setGuestCount({
                            guestType:"child", count: 0
                          }))
                        }
                      }}
                    />

                :
                <Select
                id="demo-simple-select"
                value={child}
                size="small"
                IconComponent={KeyboardArrowDownIcon} // Custom arrow icon
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9F9F9F", // Remove label border space
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9F9F9F", // Remove hover border
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9F9F9F", // Remove focus border
                  },
                  "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9F9F9F", // Ensure outline is transparent
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: 1, // Remove padding if needed
                    color:'#9F9F9F',
                    fontSize:"12px"
                  },
                }}
                onChange={(event) => dispatch(setGuestCount({
                  guestType:"child", count: Number(event.target.value)
                }))}
              >
                {new Array(10).fill(null).map((_, index) => (
                  <MenuItem key={index} value={index + 1} sx={{color:'#9F9F9F',
                  fontSize:"12px"}}>
                    {index + 1}
                  </MenuItem>
                ))}
                <MenuItem
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mx: 0.5,
                  }}
                >
                  <Typography
                    onClick={(event) => {
                      event.stopPropagation(); // Prevent the Select from closing
                      dispatch(checkGuestLimit({
                        guestType:'child', isGreaterThan10:!isGreaterThan10?.child
                      }))
                    }}
                  >
                    +
                  </Typography>
                </MenuItem>
              </Select>
                }
      
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb:2,
            mx:3
          }}
        >
          <Typography sx={{fontSize:"12px",color:"#000000",fontWeight:"bold"}}>{t('ageCategories.Kid')}</Typography>
          <FormControl fullWidth sx={{ width: "72px", height: "32px" }}>
            <Select
              id="demo-simple-select"
              value={kid}
              size="small"
              IconComponent={KeyboardArrowDownIcon} // Custom arrow icon
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#9F9F9F", // Remove label border space
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#9F9F9F", // Remove hover border
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#9F9F9F", // Remove focus border
                },
                "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#9F9F9F", // Ensure outline is transparent
                },
                "& .MuiOutlinedInput-input": {
                  padding: 1, // Remove padding if needed
                  color:'#9F9F9F',
                  fontSize:"12px"
                },
              }}
              onChange={(event) => dispatch(setGuestCount({
                guestType:"kid", count: Number(event.target.value)
              }))}
            >
              {new Array(10).fill(null).map((_, index) => (
                <MenuItem key={index} value={index + 1} sx={{color:'#9F9F9F',
                fontSize:"12px"}}>
                  {index + 1}
                </MenuItem>
              ))}
              <MenuItem
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mx: 0.5,
                }}
              >
                <Typography
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent the Select from closing
                    dispatch(checkGuestLimit({
                      guestType:'kid', isGreaterThan10:!isGreaterThan10?.kid
                    }))
                  }}
                >
                  +
                </Typography>
                {isGreaterThan10?.kid &&
                      <TextField
                      placeholder="00"
                      name="kid"
                      value={kid} // Show value adjusted for base of 10
                      // Prevent the Select from closing
                      onClick={(e)=> e.stopPropagation()}
                      onFocus={(e)=> e.stopPropagation()}
                      sx={{
                        width: "50px",
                        height: "20px",
                        "& .MuiOutlinedInput-input": {
                          padding: 0,
                        },
                      }}
                      size="small"
                      onChange={(e) => {
                       
                       // e.preventDefault()
                        const inputValue = e.target.value;
                        const newValue = Number(inputValue);
            
                        // Update state only if the input is a valid number
                        if (!isNaN(newValue) && inputValue.trim() !== "") {
                          dispatch(setGuestCount({
                            guestType:"kid", count: newValue
                          }))
                        } else if (inputValue === "") {
                          dispatch(setGuestCount({
                            guestType:"child", count: 0
                          }))
                        }
                      }}
                    />

                }
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb:2,
            mx:3
          }}
        >
          <Typography sx={{fontSize:"12px",color:"#000000",fontWeight:"bold"}}>{t('ageCategories.Baby')}</Typography>
          <FormControl fullWidth sx={{ width: "72px", height: "32px" }}>
          {isGreaterThan10?.baby ?
                      <TextField
                      placeholder="00"
                      name="baby"
                      value={baby} // Show value adjusted for base of 10
                      // Prevent the Select from closing
                      onClick={(e)=> e.stopPropagation()}
                      onFocus={(e)=> e.stopPropagation()}
                      sx={{
                        width: "100%",
                        height: "30px",
                        "& .MuiOutlinedInput-input": {
                          padding: 1,
                          color:'#9F9F9F',
                        },
                      }}
                      type="number"
                      size="small"
                      onChange={(e) => {
                       
                       // e.preventDefault()
                        const inputValue = e.target.value;
                        const newValue = Number(inputValue);
            
                        // Update state only if the input is a valid number
                        if (!isNaN(newValue) && inputValue.trim() !== "") {
                          dispatch(setGuestCount({
                            guestType:"baby", count: newValue
                          }))
                         
                        } else if (inputValue === "") {
                          dispatch(setGuestCount({
                            guestType:"baby", count: 0
                          }))
                        }
                      }}
                    />
                    :
                    <Select
                    id="demo-simple-select"
                    value={baby}
                    size="small"
                    IconComponent={KeyboardArrowDownIcon} // Custom arrow icon
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#9F9F9F", // Remove label border space
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#9F9F9F", // Remove hover border
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#9F9F9F", // Remove focus border
                      },
                      "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#9F9F9F", // Ensure outline is transparent
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: 1, // Remove padding if needed
                        color:'#9F9F9F',
                        fontSize:"12px"
                      },
                    }}
                    onChange={(event) => dispatch(setGuestCount({
                      guestType:'baby',count:Number(event.target.value)
                    }))}
                  >
                    {new Array(10).fill(null).map((_, index) => (
                      <MenuItem key={index} value={index + 1} sx={{color:'#9F9F9F',
                      fontSize:"12px"}}>
                        {index + 1}
                      </MenuItem>
                    ))}
                    <MenuItem
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mx: 0.5,
                      }}
                    >
                      <Typography
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent the Select from closing
                          dispatch(checkGuestLimit({
                            guestType:'baby', isGreaterThan10:!isGreaterThan10?.baby
                          }))
                        }}
                      >
                        +
                      </Typography>
                    </MenuItem>
                  </Select>
                }
         
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mx:3
          }}
        >
          <Typography sx={{fontSize:"12px",color:"#000000",fontWeight:"bold"}}>{t('ageCategories.Toddler')}</Typography>
          <FormControl fullWidth sx={{ width: "72px", height: "32px" }}>
          {isGreaterThan10?.toddler ?
                      <TextField
                      placeholder="00"
                      name="toddler"
                      value={toddler} // Show value adjusted for base of 10
                      type='number'
                      // Prevent the Select from closing
                      onClick={(e)=> e.stopPropagation()}
                      onFocus={(e)=> e.stopPropagation()}
                      sx={{
                        width: "100%",
                        height: "30px",
                        "& .MuiOutlinedInput-input": {
                          padding: 1,
                          borderColor:"#C9C9C9",
                          color:'#9F9F9F',
                        },
                       
                      }}
                      size="small"
                      onChange={(e) => {
                       
                       // e.preventDefault()
                        const inputValue = e.target.value;
                        const newValue = Number(inputValue);
            
                        // Update state only if the input is a valid number
                        if (!isNaN(newValue) && inputValue.trim() !== "") {
                          dispatch(setGuestCount({
                            guestType:"toddler", count: newValue
                          }))
                          
                        } else if (inputValue === "") {
                         dispatch(setGuestCount({
                            guestType:"toddler", count: 0
                          }))
                        }
                      }}
                    />
                    :
                    <Select
                    id="demo-simple-select"
                    value={toddler}
                    size="small"
                    IconComponent={KeyboardArrowDownIcon} // Custom arrow icon
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#9F9F9F", // Remove label border space
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#9F9F9F", // Remove hover border
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#9F9F9F", // Remove focus border
                      },
                      "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#9F9F9F", // Ensure outline is transparent
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: 1, // Remove padding if needed
                        color:'#9F9F9F',
                        fontSize:"12px"
                      },
                    }}
                    onChange={(event) => dispatch(setGuestCount({
                      guestType:'toddler', count:Number(event.target.value)
                    }))}
                  >
                    {new Array(10).fill(null).map((_, index) => (
                      <MenuItem key={index} value={index + 1} sx={{color:'#9F9F9F',
                      fontSize:"12px"}}>
                        {index + 1}
                      </MenuItem>
                    ))}
                    <MenuItem
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mx: 0.5,
                      }}
                    >
                      <Typography
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent the Select from closing
                          dispatch(checkGuestLimit({
                            guestType:'toddler', isGreaterThan10:!isGreaterThan10?.toddler
                          }))
                          
                        }}
                      >
                        +
                      </Typography>
                    </MenuItem>
                  </Select>
                }
          
          </FormControl>
        </Box>
      </Box>
    </Box>
   
  </Popover>
   
  );
};

export default Addguest;
