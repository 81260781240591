const BASE_URL = process.env.REACT_APP_API_URL;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY

const apiEndpoints = {
  GET_LANGUAGE: `${BASE_URL}api/Widget/GetLanguages?key=${process.env.REACT_APP_API_KEY}`,
  GET_WIDGET_SETTING: (language) =>
        `${BASE_URL}api/Widget/GetSettings?key=${process.env.REACT_APP_API_KEY}&lang=${language}`,
    GET_WIDGET_TITLE_AND_INFO: (setupID, language) =>
        `${BASE_URL}api/Widget/GetWidgetTitleandInfo?idwidgetsetup=${setupID}&lang=${language}`,
  GET_WIDGET_POSITION: (setupID) =>
    `${BASE_URL}api/Widget/GetWidgetPosition?idwidgetsetup=${setupID}`,
  GET_WIDGET_SETUP: (setupID) =>
    `${BASE_URL}api/Widget/GetWidgetSetup?idwidgetsetup=${setupID}`,
  GET_CALENDER_DETAILS: (
    key,
    noOfGuests,
    widgetId,
    reservationRequestMin,
    reservationRequestMax = 0
  ) =>
    `${BASE_URL}api/Widget/GetWidgetChecksOnFieldChange?key=${key}&numberOfGuests=${noOfGuests}&idwidgetsetup=${widgetId}&reservationRequestMin=${reservationRequestMin}&reservationRequestMax=${reservationRequestMax}`,
  GET_RESERVATION_TYPE: `${BASE_URL}api/Widget/GetReservationTypes`,
  GET_PRODUCTS_RESERVATION_TUPE: (key, idproducthoreca) =>
    `${BASE_URL}api/Widget/GetProductForReservationType?key=${key}&idproducthoreca=${idproducthoreca}`,
  GET_FREE_TABLES: `${BASE_URL}api/Widget/GetFreeTables`,
  GET_RESERVATION_GROUP_TYPE: `${BASE_URL}api/Widget/GetReservationGroupTypes`,
  GET_GUEST_DETAILS: (key, ID,lang) =>
       // `${BASE_URL}api/Widget/GetFields?id=${ID}&key=${key}&lang=${lang}`,
      `${BASE_URL}api/Widget/GetGuestDetails?idreservationtype=${ID}&key=${key}&lang=${lang}`,
  GET_EXTRA_PRODUCTS_DETAILS: (ID) =>
    `${BASE_URL}api/Widget/GetUpsaleProductsForReservationType?idreservationtype=${ID}`,
  CREATE_RESERVATION: `${BASE_URL}api/Widget/CreateReservation `,
  TRANSACTIONCOST_RESTYPE: (key, ID) =>
    `${BASE_URL}api/Widget/GetTransactionCostForReservationType?key=${key}&idreservationtype=${ID}`,
  COMBINATION_WIDGET: (
    idreservationtype,
    key,
    arrivaldate,
    arrivalTime,
    departureTime,
    totalPeople,
    lang
  ) =>
    `${BASE_URL}api/Widget/GetCombinationsForWidget?idreservationtype=${idreservationtype}&key=${key}&arrivaldate=${arrivaldate}&arrivalTime=${arrivalTime}&departureTime=${departureTime}&totalPeople=${totalPeople}&language=${lang}`,
  GET_FEEDBACK:(key)=>`${BASE_URL}api/Widget/GetEnquete?key=${key}`,
  CREATE_FEEDBACK:`${BASE_URL}api/Widget/CreateEnquete`,
  CANCEL_RESERVATION:(key)=>`${BASE_URL}api/Widget/CancelReservation?key=${key}&secretkey=${SECRET_KEY}`,
  GET_RESERVATION_DETAILS:(key)=>`${BASE_URL}api/Widget/GetReservationDetails?key=${key}&secretkey=${SECRET_KEY}`
};
export default apiEndpoints;
